<template>
  <div id="main">
<!--    背景-->
    <v-carousel
        hide-delimiters
        :show-arrows="false"
        v-model="model"
        style="position: fixed;height: 100%;z-index: -1">
      <v-carousel-item
          v-for="(item,index) in list" :key="index"
          cover
      >
        <video
            :ref="'videoPlayer'+index"
            autoplay loop muted
            style="width: 100%;height: 100%;object-fit: fill;">
          <source :src="item.videourl">
        </video>
      </v-carousel-item>
    </v-carousel>


    <!--    导航-->
    <div class="d-flex align-end justify-center mb-6 pb-6" style="height: 100vh">
      <v-sheet
          max-width="80vw"
          color="rgba(255, 255, 255, 0.3)"
          :elevation="15"
      >
        <v-slide-group
            v-model="model"
            class="pa-2"
            selected-class="bg-primary"
            show-arrows
        >
          <v-slide-group-item
              v-for="(item,index) in list"
              :key="index"
              v-slot="{ selectedClass }"
          >
            <v-card
                :class="['ma-2', selectedClass]"
                color="rgba(255, 255, 255, 0.3)"
                height="80"
                width="80"
                @click="selectOne(item)"
            >
              <div class="d-flex fill-height align-center justify-center">
                <v-scale-transition>
                  <v-img :src="item.img"></v-img>
                </v-scale-transition>
              </div>
            </v-card>
          </v-slide-group-item>
        </v-slide-group>

      </v-sheet>

    </div>

  </div>
</template>

<script>

export default {
  name: 'HelloWorld',
  components: {},
  props: {
    msg: String
  },
  data: () => ({
    model: 0,
    list: [{
      videourl: '/mp4/Aru_dress.mp4',
      img:'/img/baota.svg',
      url:'https://baota.wufangzhen.com',
      i: 0,
    }, {
      videourl: '/mp4/Haruka_NY.mp4',
      img:'/img/cloud.svg',
      url:'https://cloud.wufangzhen.com',
      i: 1,

    }, {
      videourl: '/mp4/Kayoko_dress.mp4',
      img:'/img/wiki.svg',
      url:'https://wiki.wufangzhen.com',
      i: 2,
    }, {
      videourl: '/mp4/Kikyo.mp4',
      url:'https://pve.wufangzhen.com',
      i: 3,
      img:'/img/pve.png',
    }, {
      videourl: '/mp4/Misaka.mp4',
      url:'https://jrebel.wufangzhen.com',
      i: 4,
      img:'/img/jrebel.svg',

    }, {
      videourl: '/mp4/saori_nointro.mp4',
      url:'https://git.wufangzhen.com',
      i: 5,
      img:'/img/git.svg',
    }],
  }),
  methods: {
    selectOne(item){
      if (this.model === item.i){
        window.open(item.url,'_blank')
      }
      this.model = item.i
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
